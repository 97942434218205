<template>
    <div class="login-main">         
        <div class="container">            
            <div class="vx-row justify-center">
                <!-- Login Left -->
                <div class="vx-col lg:w-2/3 w-full">
                    <div class="vx-row justify-center login-inner">
                        <div class="login-colors-coustmize">
                            <vs-button @click="active = !active" :aria-pressed="active ? 'true' : 'false'"  radius type="filled" color="success" class="login-theme-btn" icon-pack="feather" icon="icon-edit-2"></vs-button>
                            <ul :class="{ activeToggle: active }">
                                <!-- Color Square -->
                                <li class="bg-first"></li>
                                <li class="bg-second"></li>
                                <li class="bg-third"></li>                        
                                <li class="bg-danger"></li>
                                <li class="bg-fourth"></li>
                            </ul>
                        </div>
                        <div class="vx-col md:w-1/2 w-full p-0">
                            <div class="left-login-content">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 351.53 465"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M317.31,465H0V0H113.57l182.8,204C362.73,278,368.6,385.23,317.31,465Z"/></g></g></svg>
                              <div class="overly-text">
                                <h2>Welcome</h2>
                                <img src="../../assets/images/login.png">
                                <p>Please login to your account</p>
                              </div>
                            </div>
                        </div>
                        <div class="vx-col md:w-1/2 w-full pr-md-0 ">
                            <div class="login-form">
                                <h2>Login</h2>
                                <form data-vv-scope="loginForm">
                                    <div class="form-grp">                                            
                                        <vs-input name="email" v-model="email" class="w-full" />
                                        <label>Email</label>
                                        <feather-icon icon="UserIcon"></feather-icon>
                                    </div>
                                    <div class="form-grp">                                                                             
                                        <vs-input type="password" name="password" v-model="password" class="w-full" />
                                        <label>Password</label>
                                        <feather-icon icon="KeyIcon"></feather-icon>
                                    </div>
                                    <div class="flex flex-wrap items-center justify-center mt-8 w-full">
                                        <vs-button color="success" id="user-login" :disabled="button_loading" type="filled" 
                                        class="w-full">Login</vs-button>
                                    </div>                                                                      
                                    <router-link to="Forgotpassword" class="forgot-pass float-right">Forgot Password? </router-link>                                                                        
                                </form>
                            </div>
                        </div> 
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            email: "",
            password: "",  
            active: false
        };
    },
    methods: {
       
    }
    
}
</script>

<style lang="scss">
.login-main {
    align-content: flex-end;
    position: relative;
    min-height: 100vh;
    z-index: 1;
    padding: 24px 0px;
    display: flex;     
    align-items: center;   
    position: relative;    
    background: rgba(var(--vs-success), 0.2);
    overflow: hidden;
    &:after{
        position: absolute;
        left: -18%;
        bottom: -40px;
        height: 110%;
        width: 58%;
        content: "";
        background: rgba(var(--vs-success), 0.2);
        border-radius: 20%;
        transform: rotate(-42deg);
    }
    .login-inner{
        box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.05);
        float: left;
        width: 100%;    
        border-radius: 1.5em;
        background-color: #ffffff;            
        position: relative;        
        margin: 0px;     
        .login-colors-coustmize{
            position: absolute;
            right: -10px;
            top: -10px;
            z-index: 2;
            ul{
                float: left;                                                     
                width: 100%;
                background: #fff;
                text-align: center;
                display: flex;
                justify-content: center;                
                align-items: center;
                padding: 16px 4px;
                border-radius: 0.8em;
                box-shadow: -3px 4px 6px -1px rgba(var(--vs-primary), 0.1);        
                margin-top: 24px;
                position: relative;   
                margin-left: 12px;  
                display: none;
                &.activeToggle{
                    display: flex;
                }           
                &:after{                    
                    right: 0;
                    border-color: transparent transparent #fff #fff;
                    -webkit-transform-origin: 0 0;
                    transform-origin: 0 0;
                    -webkit-transform: rotate(136deg);
                    transform: rotate(136deg);                    
                   box-shadow: 0px 14px 24px 1px rgba(var(--vs-primary), 0.2); 
                    margin-left: 50%;
                    content: "";
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    top:1px;
                        
                        text-align: center;
                    border: 0.75em solid black;            
                }                
                li{                                 
                    float: left;
                    width: 36px;
                    height: 36px;
                    border: 4px solid #fff;
                    box-shadow: 0px 0px 0px 1px #ccc;
                    border-radius: 50%;                    
                    margin: 8px 4px;
                    cursor: pointer;
                }
            }
        }
        .left-login-content{
            float: left;
            width: 100%;
            height: 100%;           
            position: relative;
            z-index: 1;     
            overflow: hidden;    
            border-radius: 1.5em 0px 0px 1.5em;
            .overly-text{
                position: absolute;
                left: 0;
                top: 0;
                padding: 30px 40px;
                display: flex;
                height: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                justify-content: space-around;
                flex-wrap: wrap;
                img{
                    max-width: 66%;
                    float: left;
                    opacity: 0.2;
                    width: 100%;
                    margin-top: 160px;
                    margin-left: 0px;
                    filter: brightness(0) invert(1);
                }
                h2{
                    float: left;                    
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    color: rgba(256, 256, 256, 0.5);
                    font-size: 40px;
                    font-weight: 700;                    
                    letter-spacing: 1.5px;
                    display: block;
                    position: absolute;
                    top: 116px;
                    left: -50px;
                }
                p{
                    float: left;   
                    width: 100%;
                    color: rgba(256, 256, 256, 0.7);
                    margin-top: auto;
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: uppercase;
                    line-height: 24px;
                }
            }
            svg{
                float: left;
                height:100%;
                path{
                    fill:  rgba(var(--vs-success), 1);
                }
            } 
                            
        }
        .login-form{
            float: left;
            width: 100%;
            padding: 72px 70px 90px 50px;
            position: relative;
            h2{
                float: left;
                width: 100%;
                margin-bottom: 40px;
                color: rgba(var(--vs-success), 1);
                font-size: 35px;
                letter-spacing: 1px;
                text-align: center;
                line-height: 45px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .form-grp{
                float: left;
                width: 100%;
                margin-bottom: 30px;   
                display: flex;
                flex-wrap: wrap;
                flex-direction: column-reverse;   
                position: relative;
                .feather-icon {
                    position: absolute !important;
                    right: 0;
                    bottom:8px;
                    svg{
                        width: 20px;
                        height: 20px;
                        color: rgba(var(--vs-success), 0.7);
                    }
                }   
                .vs-input.isFocus + label + .feather-icon  svg{
                    color: rgba(var(--vs-success), 1);
                }
                .vs-input{
                    float: left;
                }
                .vs-input input{
                    border: 0px !important;
                    border-bottom: 2px solid  rgba(var(--vs-success), 0.3) !important;
                    border-radius: 0px;
                    padding: 8px 32px 8px 0px !important;
                    &:focus{
                        box-shadow: none !important;
                        border-bottom: 2px solid  rgba(var(--vs-success), 0.6) !important;
                    }
                }                
                label{
                    color: rgba(var(--vs-success), 0.6);
                    font-weight: 500;
                    font-size: 15px;
                    margin: 0px;
                    float: left;
                    width: 100%;
                }
                .vs-input.isFocus + label{
                   color: rgba(var(--vs-success), 0.9); 
                }                
            }
            .forgot-pass{
                float: right;
                font-weight: 700;
                color: rgba(var(--vs-success), 1) !important;
                position: absolute;
                right: 32px;
                bottom: 0px;                                             
            } 
        }
    }
            
}


.vs-button.vs-radius.login-theme-btn{
    border-radius: 50% !important;
    width: 50px!important;
    height: 50px!important;
    float: right !important;
    box-shadow: 0px 0px 10px 2px rgba(var(--vs-primary), 0.3);
    i{
        font-size: 20px;
    }
}

/* Resposive */
@media (max-width: 1365px) {
    .login-main .login-inner .login-colors-coustmize ul{
        margin-left: 0px;
    }
}

@media (max-width: 1199px) {   
    .login-main .login-inner .left-login-content svg {
        height: 100%;
        width: 115%;
        object-fit: cover;
        margin-left: -15%;
    }    
    .login-main .login-inner .left-login-content .overly-text h2{
        font-size: 32px;
        top: 92px;
    }  
}

@media (max-width: 767px) {   
    .login-main:after{
        display: none;
    }
    .login-main .login-inner .login-form{
        padding: 32px 16px;
    }
    .login-main .login-inner .login-form .forgot-pass{
        position: static;
        margin-top: 16px;
        width: 100%;
        text-align: center;
    }
    .login-main .login-inner .left-login-content{
        background: rgba(var(--vs-success), 1);
        height: auto;
        border-radius: 1.5em 1.5em 0px 0px;
    }
    .login-main .login-inner .left-login-content svg{
        display: none;
    }
    .login-main .login-inner .left-login-content .overly-text{
        height: auto;
        position: static;
    }
    .login-main .login-inner .left-login-content .overly-text img{
        max-width: 180px;
        margin-top: 0px;
    }  
    .login-main .login-inner .left-login-content .overly-text p {
        float: left;
        width: 100%;
        margin-top: 24px;
        text-align: center;
    } 
}

@media (max-width: 575px) {   
    .login-main .login-inner .left-login-content .overly-text img {
        max-width: 120px;
    }
    .login-main .login-inner .left-login-content .overly-text h2{
        font-size: 26px;
        top: 76px;
        left:-40px
    }
    .login-main .login-inner .login-form h2 {
        font-size: 24px;
        margin-bottom: 24px;
    }
     .login-main .login-form {
        padding: 24px 0px;
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {  
  -webkit-text-fill-color: #666;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}
</style>
